import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDefaultSimpleMessageOptions } from 'src/app/services/alert/alert-service.interface';
import { BoxComponent } from 'src/app/shared/components/molecules/boxes/box.class';
import { DefaultBoxComponent } from '@molecules/boxes/default/default.component';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgIf, NgClass } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'mt-default-simple-message',
  templateUrl: './default-simple-message.component.html',
  styleUrls: ['./default-simple-message.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, MtSvgComponent, DefaultBoxComponent, ButtonComponent]
})
export class DefaultSimpleMessageComponent implements OnInit {
  @Input() parent!: IDefaultSimpleMessageOptions;

  /**
   * Used to show confetti animation in the modal
   * @defaultValue false
   */
  @Input() showConfetti = false;

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {
    this.parent.icon = BoxComponent.getIcon(this.parent.type);
  }
}
