import { Directive, ElementRef, Input, OnInit, inject } from '@angular/core';
import { AuthClaims, AuthService } from '@services/auth/auth.service';

/**
 * Directive applied to an element that is protected by a claim
 *
 * When the user can't provide the necessary claim, the element is removed from the DOM
 */
@Directive({
  selector: '[mt-claim-authorization]',
  standalone: true
})
export class ClaimAuthorizationDirective implements OnInit {
  private authService = inject(AuthService);
  private elementRef = inject(ElementRef);

  /**
   * Claim required to access the element
   */
  @Input() requiredClaim!: AuthClaims;

  /**
   * When checking against multiple claims only a single one needs to be provided
   *
   * @remarks Only used in the sidebar to determine if a menu or sub-menu should be shown
   */
  @Input() requiredClaims!: AuthClaims[];

  ngOnInit(): void {
    const claims = this.requiredClaim ? [this.requiredClaim] : this.requiredClaims;
    this.authService.hasClaim(...claims).subscribe(hasClaim => {
      if (!hasClaim) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        this.elementRef.nativeElement.remove();
      }
    });
  }
}
