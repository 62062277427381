import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICustomModalOptions } from 'src/app/services/alert/alert-service.interface';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgIf, NgTemplateOutlet } from '@angular/common';

/**
 * Creates a custom modal that follows a default sections of header, body, and footer.
 *
 * **BEST PRACTICE**
 *
 * It is best to define the header directly in the component like mentioned in the
 * HEADER/no_directive item.
 *
 * **HEADER**
 *
 * There are three options for the header section:
 * - directive `modal-header` - completely customaziable without close button
 * - directive `modal-header-close` - completely customaziable with close button,
 * - no directive - though using `headline="text"` or `headline="{{'translateTag'|translate}}""`
 *
 * **BODY**
 *
 * Use the `modal-body` directive for this section.
 *
 * **FOOTER**
 *
 * There are three options for the footer section:
 * - directive `modal-footer` - completely customaziable
 * - directive `modal-footer-left` - aligns items to the left
 * - directive `modal-footer-right` aligns items to the right
 */
@Component({
  selector: 'mt-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, MtSvgComponent]
})
export class CustomModalComponent implements OnInit, AfterViewInit {
  /**
   * View close button, if no custom header chosen
   * @defaultValue true
   */
  @Input() closeBtn = true;

  /**
   * Header background color
   * @remarks available colors `secondary`
   * @defaultValue 'secondary'
   */
  @Input() headerColor = 'secondary';

  /**
   * Header text, if no custom header chosen
   * @remarks translate before hand
   */
  @Input() headline?: string;

  /**
   * The settings to create the custom modal.
   */
  @Input() parent = {} as ICustomModalOptions;

  /**
   * The settings to use a custom theme.
   * @remarks the theme should be defined in the SCSS file.
   * @defaultValue ''
   */
  @Input() theme = '';

  @ViewChild('modalHeader') modalHeader!: ElementRef;
  @ViewChild('modalHeaderClose') modalHeaderClose!: ElementRef;

  @ViewChild('modalFooter') modalFooter!: ElementRef;
  @ViewChild('modalFooterLeft') modalFooterLeft!: ElementRef;
  @ViewChild('modalFooterRight') modalFooterRight!: ElementRef;

  showModalHeader = true;
  showModalHeaderClose = true;

  showModalFooter = true;
  showModalFooterLeft = true;
  showModalFooterRight = true;

  constructor(public modal: NgbActiveModal, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (typeof this.closeBtn !== 'undefined') {
      this.parent.closeBtn = this.closeBtn;
    }
    if (typeof this.headerColor !== 'undefined') {
      this.parent.headerColor = this.headerColor;
    }
    if (typeof this.headline !== 'undefined') {
      this.parent.headline = this.headline;
    }
    if (typeof this.theme !== 'undefined') {
      this.parent.theme = this.theme;
    }
  }

  ngAfterViewInit(): void {
    if (!this.parent.headline) {
      this.showModalHeader = this.checkElementHasChildren(this.modalHeader);
      this.showModalHeaderClose = this.checkElementHasChildren(this.modalHeaderClose, 1);
    }
    this.showModalFooter = this.checkElementHasChildren(this.modalFooter);
    this.showModalFooterLeft = this.checkElementHasChildren(this.modalFooterLeft);
    this.showModalFooterRight = this.checkElementHasChildren(this.modalFooterRight);
    this.cdRef.detectChanges();
  }

  /**
   * Checks if the `elem` exists and has the correct amount of children.
   * @private
   * @param elem - self
   * @param amount - the amount of children the element should have minus 1
   * @defaultValue `amount = 0`
   * @returns if the `elem` should be shown
   */
  private checkElementHasChildren(elem: ElementRef, amount = 0): boolean {
    // eslint-disable-next-line
    return elem.nativeElement && elem.nativeElement.children.length > amount;
  }
}
