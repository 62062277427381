import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textTruncate',
  standalone: true
})
export class TextTruncatePipe implements PipeTransform {
  transform(value: string, length = 15): string {
    return value && value.length > length ? `${value.substring(0, length)} ...` : value;
  }
}
