/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true
})
export class FilterPipe implements PipeTransform {
  transform(original: any[], fieldName: string, search: string): any {
    if (!original) {
      return [];
    }

    if (original.length === 0) {
      return original;
    }

    if (search === '' || search === null) {
      return original;
    }

    search = search.toLowerCase();

    return original.filter((value: any) => {
      const a = fieldName.split('.');
      for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in value) {
          value = value[k];
        } else {
          return;
        }
      }
      const tempName: string = value.toString().toLowerCase();

      return tempName.indexOf(search) != -1;
    });
  }
}
