import * as i0 from '@angular/core';
import { Injectable, ViewEncapsulation, isDevMode, PLATFORM_ID, Inject, Optional, Directive, Input, HostListener, NgModule } from '@angular/core';
import { filter } from 'rxjs/operators';
import * as i2 from '@angular/router';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
class IntercomConfigObject {}
IntercomConfigObject.ɵfac = function IntercomConfigObject_Factory(t) {
  return new (t || IntercomConfigObject)();
};
IntercomConfigObject.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: IntercomConfigObject,
  factory: IntercomConfigObject.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntercomConfigObject, [{
    type: Injectable
  }], null, null);
})();

/**
 * A provider with every Intercom.JS method
 */
class Intercom {
  constructor(config, platformId, router, rendererFactory, document // Document
  ) {
    this.config = config;
    this.platformId = platformId;
    this.router = router;
    this.rendererFactory = rendererFactory;
    this.document = document;
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.renderer2 = this.rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });
    // Subscribe to router changes
    if (config && config.updateOnRouterChange) {
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
        this.update();
      });
    } else if (isDevMode()) {
      console.warn(`
      Common practice in single page applications is to update whenever the route changes.
      ng-intercom supports this functionality out of the box just set 'updateOnRouterChange' to true in your Intercom Module config.
       This warning will not appear in production, if you choose not to use router updating.
     `);
    }
  }
  /**
   * If you'd like to control when Intercom is loaded, you can use the 'boot' method.
   * This is useful in situations like a one-page Javascript based application where the user may not be logged in
   * when the page loads. You call this method with the standard intercomSettings object.
   */
  boot(intercomData) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const app_id = intercomData.app_id ? intercomData.app_id : this.config.appId;
    if (!app_id) {
      throw new Error('Please provide Intercom app_id either in module config or in the `boot()` method');
    }
    // Run load and attach to window
    this.loadIntercom(this.config, event => {
      // then boot the intercom js
      const data = {
        ...intercomData,
        app_id
      };
      return this._callIntercom('boot', data);
    });
  }
  /**
   * If you have the Respond product (combined with another product like Engage)
   * you should call the Intercom shutdown method to clear your users’ conversations anytime they logout
   * of your application. Otherwise, the cookie we use to track who was most recently logged in on a given device
   * or computer will keep these conversations in the Messenger for one week.
   * This method will effectively clear out any user data that you have been passing through the JS API.
   */
  shutdown() {
    return this._callIntercom('shutdown');
  }
  /**
   * Calling the update method without any other arguments will trigger the JavaScript to look for new messages
   * that should be displayed to the current user (the one whose details are in the window.intercomSettings variable)
   * and show them if they exist.
   *
   * Calling the update method with a JSON object of user details will update those fields on the current user
   * in addition to logging an impression at the current URL and looking for new messages for the user.
   */
  update(data) {
    return this._callIntercom('update', data);
  }
  /**
   * This will hide the main Messenger panel if it is open. It will not hide the Messenger Launcher.
   */
  hide() {
    return this._callIntercom('hide');
  }
  /**
   * This will show the Messenger. If there are no conversations it will open with the new message view,
   * if there are it will open with the message list.
   *
   * If a `message` parameter is supplied, it will automatically open a new message window, aliasing showNewMessage().
   *
   */
  show(message) {
    if (message) {
      return this.showNewMessage(message);
    }
    return this._callIntercom('show');
  }
  /**
   * To open the message window with the message list you can call `showMessages()`.
   */
  showMessages() {
    return this._callIntercom('showMessages');
  }
  /**
   * To open the message window with the new message view you can call showNewMessage().
   *
   * This function takes an optional parameter that can be used to pre-populate the message composer as shown below.
   */
  showNewMessage(message) {
    return this._callIntercom('showNewMessage', message);
  }
  /**
   * You can submit an event using the trackEvent method.
   * This will associate the event with the currently logged in user and send it to Intercom.
   * The final parameter is a map that can be used to send optional metadata about the event.
   *
   * You can also add custom information to events in the form of event metadata.
   */
  trackEvent(eventName, metadata) {
    return this._callIntercom('trackEvent', eventName, metadata);
  }
  /**
   * A visitor is someone who goes to your site but does not use the messenger.
   * You can track these visitors via the visitor user_id.
   * This user_id can be used to retrieve the visitor or lead through the REST API.
   */
  getVisitorId() {
    return this._callIntercom('getVisitorId');
  }
  /**
   * Alias for getVisitorId()
   * @alias getVisitorId()
   * @readonly
   */
  get visitorId() {
    return this._callIntercom('getVisitorId');
  }
  /**
   * Gives you the ability to hook into the show event. Requires a function argument.
   */
  onShow(handler) {
    return this._callIntercom('onShow', handler);
  }
  /**
   * Gives you the ability to hook into the hide event. Requires a function argument.
   */
  onHide(handler) {
    return this._callIntercom('onHide', handler);
  }
  /**
   * This method allows you to register a function that will be called when the current number of unread messages changes.
   */
  onUnreadCountChange(handler) {
    return this._callIntercom('onUnreadCountChange', handler);
  }
  /**
   * If you would like to trigger a tour based on an action a user or visitor takes in your site or application,
   * ou can use this API method. You need to call this method with the id of the tour you wish to show. The id of
   * the tour can be found in the “Use tour everywhere” section of the tour editor.
   *
   * Please note that tours shown via this API must be published and the “Use tour everywhere” section must be
   * turned on. If you're calling this API using an invalid tour id, nothing will happen.
   */
  startTour(tourId) {
    return this._callIntercom('startTour', tourId);
  }
  /**
   * Private handler to run Intercom methods safely
   */
  _callIntercom(fn, ...args) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (window.Intercom) {
      return window.Intercom(fn, ...args);
    }
    return;
  }
  injectIntercomScript(conf, afterInjectCallback) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    // Set the window configuration to conf
    window.intercomSettings = conf;
    // Create the intercom script in document
    const s = this.document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${this.id}`;
    if (s.attachEvent) {
      s.attachEvent('onload', afterInjectCallback);
    } else {
      s.addEventListener('load', afterInjectCallback, false);
    }
    if (this.renderer2 && this.renderer2.appendChild) {
      this.renderer2.appendChild(this.document.head, s);
    }
    window.Intercom('update', conf);
  }
  loadIntercom(config, afterLoadCallback) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.id = config.appId;
    const w = window;
    const ic = w.Intercom;
    // Set window config for Intercom
    w.intercomSettings = config;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', config);
      afterLoadCallback();
    } else {
      const i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      this.injectIntercomScript(config, afterLoadCallback);
    }
  }
}
Intercom.ɵfac = function Intercom_Factory(t) {
  return new (t || Intercom)(i0.ɵɵinject(IntercomConfigObject), i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(Router, 8), i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(DOCUMENT, 8));
};
Intercom.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: Intercom,
  factory: Intercom.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Intercom, [{
    type: Injectable
  }], function () {
    return [{
      type: IntercomConfigObject,
      decorators: [{
        type: Inject,
        args: [IntercomConfigObject]
      }]
    }, {
      type: Object,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: i2.Router,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [Router]
      }]
    }, {
      type: i0.RendererFactory2
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, null);
})();

/* tslint:disable:directive-selector */
class IntercomHideDirective {
  constructor(intercom) {
    this.intercom = intercom;
  }
  onClick() {
    if (this.intercomHide !== false) {
      this.intercom.hide();
    }
  }
}
IntercomHideDirective.ɵfac = function IntercomHideDirective_Factory(t) {
  return new (t || IntercomHideDirective)(i0.ɵɵdirectiveInject(Intercom));
};
IntercomHideDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IntercomHideDirective,
  selectors: [["", "intercomHide", ""]],
  hostBindings: function IntercomHideDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function IntercomHideDirective_click_HostBindingHandler() {
        return ctx.onClick();
      });
    }
  },
  inputs: {
    intercomHide: "intercomHide"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntercomHideDirective, [{
    type: Directive,
    args: [{
      selector: '[intercomHide]'
    }]
  }], function () {
    return [{
      type: Intercom
    }];
  }, {
    intercomHide: [{
      type: Input
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();

/* tslint:disable:directive-selector */
class IntercomShowMessagesDirective {
  constructor(intercom) {
    this.intercom = intercom;
  }
  onClick() {
    if (this.intercomShowMessages !== false) {
      this.intercom.showMessages();
    }
  }
}
IntercomShowMessagesDirective.ɵfac = function IntercomShowMessagesDirective_Factory(t) {
  return new (t || IntercomShowMessagesDirective)(i0.ɵɵdirectiveInject(Intercom));
};
IntercomShowMessagesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IntercomShowMessagesDirective,
  selectors: [["", "intercomShowMessages", ""]],
  hostBindings: function IntercomShowMessagesDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function IntercomShowMessagesDirective_click_HostBindingHandler() {
        return ctx.onClick();
      });
    }
  },
  inputs: {
    intercomShowMessages: "intercomShowMessages"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntercomShowMessagesDirective, [{
    type: Directive,
    args: [{
      selector: '[intercomShowMessages]'
    }]
  }], function () {
    return [{
      type: Intercom
    }];
  }, {
    intercomShowMessages: [{
      type: Input
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();

/* tslint:disable:directive-selector */
class IntercomShowNewMessageDirective {
  constructor(intercom) {
    this.intercom = intercom;
  }
  onClick() {
    const msg = this.message ? this.message : this.intercomShowNewMessage;
    if (msg) {
      this.intercom.showNewMessage(this.message);
    } else {
      this.intercom.showNewMessage();
    }
  }
}
IntercomShowNewMessageDirective.ɵfac = function IntercomShowNewMessageDirective_Factory(t) {
  return new (t || IntercomShowNewMessageDirective)(i0.ɵɵdirectiveInject(Intercom));
};
IntercomShowNewMessageDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IntercomShowNewMessageDirective,
  selectors: [["", "intercomShowNewMessage", ""]],
  hostBindings: function IntercomShowNewMessageDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function IntercomShowNewMessageDirective_click_HostBindingHandler() {
        return ctx.onClick();
      });
    }
  },
  inputs: {
    message: "message",
    intercomShowNewMessage: "intercomShowNewMessage"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntercomShowNewMessageDirective, [{
    type: Directive,
    args: [{
      selector: '[intercomShowNewMessage]'
    }]
  }], function () {
    return [{
      type: Intercom
    }];
  }, {
    message: [{
      type: Input
    }],
    intercomShowNewMessage: [{
      type: Input
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();

/* tslint:disable:directive-selector */
class IntercomShowDirective {
  constructor(intercom) {
    this.intercom = intercom;
  }
  onClick() {
    const msg = this.message ? this.message : this.intercomShow;
    if (msg) {
      this.intercom.showNewMessage(this.message);
    } else {
      this.intercom.show();
    }
  }
}
IntercomShowDirective.ɵfac = function IntercomShowDirective_Factory(t) {
  return new (t || IntercomShowDirective)(i0.ɵɵdirectiveInject(Intercom));
};
IntercomShowDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IntercomShowDirective,
  selectors: [["", "intercomShow", ""]],
  hostBindings: function IntercomShowDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function IntercomShowDirective_click_HostBindingHandler() {
        return ctx.onClick();
      });
    }
  },
  inputs: {
    message: "message",
    intercomShow: "intercomShow"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntercomShowDirective, [{
    type: Directive,
    args: [{
      selector: '[intercomShow]'
    }]
  }], function () {
    return [{
      type: Intercom
    }];
  }, {
    message: [{
      type: Input
    }],
    intercomShow: [{
      type: Input
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();

/* tslint:disable:directive-selector */
class IntercomShutdownDirective {
  constructor(intercom) {
    this.intercom = intercom;
  }
  onClick() {
    if (this.intercomShutdown !== false) {
      this.intercom.shutdown();
    }
  }
}
IntercomShutdownDirective.ɵfac = function IntercomShutdownDirective_Factory(t) {
  return new (t || IntercomShutdownDirective)(i0.ɵɵdirectiveInject(Intercom));
};
IntercomShutdownDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IntercomShutdownDirective,
  selectors: [["", "intercomShutdown", ""]],
  hostBindings: function IntercomShutdownDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function IntercomShutdownDirective_click_HostBindingHandler() {
        return ctx.onClick();
      });
    }
  },
  inputs: {
    intercomShutdown: "intercomShutdown"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntercomShutdownDirective, [{
    type: Directive,
    args: [{
      selector: '[intercomShutdown]'
    }]
  }], function () {
    return [{
      type: Intercom
    }];
  }, {
    intercomShutdown: [{
      type: Input
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();

/* tslint:disable:directive-selector */
class IntercomTrackEventDirective {
  constructor(intercom) {
    this.intercom = intercom;
  }
  onClick() {
    const e = this.event ? this.event : this.intercomTrackEvent;
    if (e && this.metadata) {
      this.intercom.trackEvent(e, this.metadata);
    } else if (e && !this.metadata) {
      this.intercom.trackEvent(e);
    } else {
      throw new Error('Error in intercomTrackEvent directive: You must specify an event to track.');
    }
  }
}
IntercomTrackEventDirective.ɵfac = function IntercomTrackEventDirective_Factory(t) {
  return new (t || IntercomTrackEventDirective)(i0.ɵɵdirectiveInject(Intercom));
};
IntercomTrackEventDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IntercomTrackEventDirective,
  selectors: [["", "intercomTrackEvent", ""]],
  hostBindings: function IntercomTrackEventDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function IntercomTrackEventDirective_click_HostBindingHandler() {
        return ctx.onClick();
      });
    }
  },
  inputs: {
    event: "event",
    intercomTrackEvent: "intercomTrackEvent",
    metadata: "metadata"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntercomTrackEventDirective, [{
    type: Directive,
    args: [{
      selector: '[intercomTrackEvent]'
    }]
  }], function () {
    return [{
      type: Intercom
    }];
  }, {
    event: [{
      type: Input
    }],
    intercomTrackEvent: [{
      type: Input
    }],
    metadata: [{
      type: Input
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class IntercomModule {
  static forRoot(config) {
    return {
      ngModule: IntercomModule,
      providers: [Intercom, {
        provide: IntercomConfigObject,
        useValue: config
      }]
    };
  }
}
IntercomModule.ɵfac = function IntercomModule_Factory(t) {
  return new (t || IntercomModule)();
};
IntercomModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: IntercomModule
});
IntercomModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [Intercom, IntercomConfigObject],
  imports: [RouterModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntercomModule, [{
    type: NgModule,
    args: [{
      imports: [RouterModule],
      declarations: [IntercomHideDirective, IntercomShowMessagesDirective, IntercomShowNewMessageDirective, IntercomShowDirective, IntercomShutdownDirective, IntercomTrackEventDirective],
      exports: [IntercomHideDirective, IntercomShowMessagesDirective, IntercomShowNewMessageDirective, IntercomShowDirective, IntercomShutdownDirective, IntercomTrackEventDirective],
      providers: [Intercom, IntercomConfigObject]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Intercom, IntercomConfigObject, IntercomHideDirective, IntercomModule, IntercomShowDirective, IntercomShowMessagesDirective, IntercomShowNewMessageDirective, IntercomShutdownDirective, IntercomTrackEventDirective };
