<button
  [type]="type"
  class="{{ klass }} {{ color }}"
  [disabled]="disabled"
  [ngClass]="{ mt_dropdown: dropdown, 'mt-button-outline': outline }"
  [ngStyle]="{ 'outline-color': outline ? outlineColor : 'none' }"
  *transloco="let translate"
>
  <span *ngIf="_loading">
    <div class="spinner-border spinner-border-sm me-2" role="status"></div>
    <span>{{ translate(_loadingText) }}</span>
  </span>

  <ng-container *ngIf="!_loading">
    <span
      *ngIf="icon"
      [ngStyle]="{
        'margin-right': text !== undefined ? '4px' : '0px'
      }"
      class="{{ icon }}"
      aria-hidden="true"
    ></span>

    <span *ngIf="text">{{ translate(text) }}</span>
    <ng-content></ng-content>
    <span *ngIf="dropdown" style="margin-left: 4px" class="caret"></span
  ></ng-container>
</button>
