import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { AuthClaims } from '@services/auth/auth.service';
import { ButtonDirective } from '@shared/directives/button/button.directive';
import { ClaimAuthorizationDirective } from '@shared/directives/claim-authorization/claim-authorization.directive';

@Component({
  selector: 'mt-emptystate',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  standalone: true,
  imports: [ButtonComponent, ButtonDirective, ClaimAuthorizationDirective, NgbTooltip, TranslocoModule]
})
export class EmptyStateComponent {
  _svg!: string;
  _iconColor = 'htf-emptystate';

  emptystateImgClass?: string;
  emptystateTitleClass?: string;
  emptystateSubitleClass?: string;

  loadingImgClass?: string;
  loadingTitleClass?: string;
  loadingSubitleClass?: string;

  svgSource?: string;

  /**
   * Shows the empty state.
   * @defaultValue false
   */
  @Input() showEmpty = false;

  /**
   * Shows loading animation.
   * @defaultValue false
   */
  @Input() loadingPromise = false;

  /**
   * Color of the title.
   */
  @Input() titleColor?: string;

  /**
   * Color of the subtitle.
   */
  @Input() subtitleColor?: string;

  /**
   * Color of the icon.
   * @defaultValue 'htf-emptystate'
   */
  @Input()
  get iconColor() {
    return this._iconColor;
  }
  set iconColor(val: string) {
    if (!val) {
      this._iconColor = 'htf-emptystate';
    } else {
      this._iconColor = val;
    }
  }

  /**
   * Translation tag of the loader title.
   * @defaultValue 'loading_data'
   */
  @Input() loaderTitle = 'loading_data';

  /**
   * Translation tag of the loader sub-title.
   * @defaultValue 'please_wait'
   */
  @Input() loaderSubTitle = 'please_wait';

  /**
   * Translation tag of the title.
   */
  @Input() hltitle!: string;

  /**
   * Translation tag of the subtitle.
   */
  @Input() subtitle!: string;

  /**
   * Translation tag of the button.
   */
  buttonText = input<string>();

  /**
   * Translation tag of disabled tool tip.
   * @remarks Requires `disabled = true`.
   */
  @Input() disableTooltip?: string;

  /**
   * When a required claim can't be provided the button will be removed
   */
  @Input() requiredClaim!: AuthClaims;

  /**
   * When a required claims can't be provided the button will be removed
   */
  @Input() requiredClaims!: AuthClaims[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() buttonAction: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Icon name.
   */
  @Input()
  get svg() {
    return this._svg;
  }
  set svg(val: string) {
    this._svg = val;
    this.svgSource = `assets/images/emptystates/${this._svg}_es_svg.svg`;
    if (this._svg.includes('_circle')) {
      this.emptystateImgClass = 'emptystate__img_circle';
      this.emptystateTitleClass = 'emptystate__title_circle';
      this.emptystateSubitleClass = 'emptystate__subtitle_circle';
      this.loadingImgClass = 'loading__img_circle';
      this.loadingTitleClass = 'loadingstate__title_circle';
      this.loadingSubitleClass = 'loadingstate__subtitle_circle';
    }
  }

  /**
   * Disables the button.
   * @defaultValue false
   */
  @Input() disabled = false;

  /**
   * Button click action.
   */
  clickAction(): void {
    this.buttonAction.emit(this.buttonAction);
  }
}
