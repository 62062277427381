/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { Intercom } from '@supy-io/ngx-intercom';
import { MailtasticAuthorizationDetails } from 'src/app/model/enums/mailtastic-authorization-details.enum';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IUserLoginSuccess, IntercomInfo } from '../user/user-service.interface';
import { ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  /**
   * Hide and show intercom luncher with boolean
   */
  private _hideShowDefaultLuncher$ = new ReplaySubject<boolean>(1);
  hideShowDefaultLuncher$ = this._hideShowDefaultLuncher$.asObservable().pipe(
    tap(result => {
      this.intercom.update({
        hide_default_launcher: result
      });
    })
  );

  constructor(public intercom: Intercom, private authService: AuthService) {}

  /**
   * Initiates the connection with intercom
   */
  boot(data?: { content: string; medium: string; name: string; source: string; term: string }): void {
    const userUtm = {
      app_id: environment.intercomAppId,
      email: this.authService.getLocalStorage(MailtasticAuthorizationDetails.email),
      companyName: this.authService.getLocalStorage(MailtasticAuthorizationDetails.companyName),
      utm_content: data?.content,
      utm_medium: data?.medium || 'Inbound',
      utm_campaign: data?.name || 'Marketing',
      utm_source: data?.source || 'Website',
      utm_term: data?.term || 'Paid',
      user_id: this.authService.getLocalStorage(MailtasticAuthorizationDetails.adminId)
    };

    const intercomAttributes = {
      app_id: environment.intercomAppId,
      email: this.authService.getLocalStorage(MailtasticAuthorizationDetails.email),
      companyName: this.authService.getLocalStorage(MailtasticAuthorizationDetails.companyName),
      user_id: this.authService.getLocalStorage(MailtasticAuthorizationDetails.adminId)
    };
    data ? this.intercom.boot(userUtm) : this.intercom.boot(intercomAttributes);
  }

  /**
   * Sends the information about the logged in user
   * @param result - The data of logged in user
   */
  userLoggedIn(result: IUserLoginSuccess): void {
    if (!result.firstname || !result.lastname || !result.email) {
      return;
    }

    //if firstname and lastname is missing then use email as name
    let name = '';
    if (!result.firstname && !result.lastname) {
      name = result.email;
    } else {
      name = result.firstname + ' ' + result.lastname;
    }

    const intercomAttributes = {
      app_id: environment.intercomAppId,
      user_id: result.adminId, // TODO: add here result.adminId
      gender: result.gender,
      email: result.email,
      created_at: Date.parse(result.createdAt.toString()), // Signup date as a Unix timestamp,
      companyName: result.companyName,
      cogsig_owner: 1,
      user_belongs_to_cogsigfree_account: 1,
      name: name,
      pgl_self_checkout: this.authService.decodeToken(result.token).forceAllow || false,
      company: {
        company_id: result.accountId,
        name: result.companyName,
        company_employee_potential: result.amountOfEmployees || 0
      }
    };
    this.intercom.boot(intercomAttributes);
  }

  /**
   * Sends the information to update in intercom
   * @param info - The inforamtion to update
   * @param isNonMTAdmin - The check for non MT admin
   */
  updateInfo(info: IntercomInfo, isNonMTAdmin = false): void {
    const intercomAttributes = {
      ...info,
      user_id: isNonMTAdmin
        ? this.authService.decodeToken(this.authService.getTokenForNonMtAdmin()).adminId
        : this.authService.decodeToken().adminId,
      app_id: environment.intercomAppId
    };

    this.intercom.boot(intercomAttributes);
  }

  /**
   * Wrapper for tracking event safely
   * @param event - The user event name
   */
  trackEvent(event: string): void {
    this.boot();
    this.intercom.trackEvent(event);
  }

  /**
   * Tells to intercom that user has logged out
   */
  shutDown() {
    this.intercom.shutdown();
  }

  /**
   * Hide and show intercome luncher
   * @param value - Boolean
   */
  isHideIntercomLuncher(value: boolean): void {
    this._hideShowDefaultLuncher$.next(value);
  }

  /**
   * Check intercom chat icon exist on HTML Element
   * @returns Boolean value
   */
  isIntercomIconAvailable(): boolean {
    return !!document.getElementById('intercom-frame');
  }
}
