import { Directive, ElementRef, Input, OnInit, Renderer2, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Applies `mt-button` styling to `<button>` elements
 *
 * Should be used instead of `mt-button` since it allows direct access to the element to apply other directives i.e.: mt-claim-authorization
 */
@Directive({
  selector: '[mt-button]',
  standalone: true
})
export class ButtonDirective implements OnInit {
  private elementRef = inject(ElementRef);
  private renderer = inject(Renderer2);
  private translateService = inject(TranslocoService);

  private iconName = ''; // Icon name with glyphicon prefix
  private btnText = ''; // Translated text tag to be displayed

  /**
   * Applies an icon to the button
   */
  @Input() set icon(iconName: string) {
    this.iconName = `glyphicon-${iconName}`;
  }

  /**
   * Text shown in the button
   *
   * @remarks Should be a translation tag
   */
  @Input() set text(btnText: string) {
    this.btnText = this.translateService.translate(btnText);
  }

  /**
   * Type of button that determines the button's color
   *
   * @defaultValue 'primary'
   */
  @Input() color: 'primary' | 'secondary' | 'white' = 'primary';

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const nativeButton = this.elementRef.nativeElement;

    this.renderer.addClass(nativeButton, `mt_button`);
    this.renderer.addClass(nativeButton, `mt_${this.color}`);

    if (this.iconName) {
      this.renderer.appendChild(nativeButton, this.renderIcon(this.iconName, this.btnText));
    }

    if (this.btnText) {
      this.renderer.appendChild(nativeButton, this.renderBtnText(this.btnText));
    }
  }

  /**
   * Renders and returns the element containing the icon for the button
   * @param iconName - Icon name to render
   * @param btnText - Button text - determines if `margin-right` is applied
   * @returns Icon element
   */
  private renderIcon(iconName: string, btnText: string): unknown {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const iconElement = this.renderer.createElement('span');

    this.renderer.addClass(iconElement, 'glyphicon');
    this.renderer.addClass(iconElement, iconName);

    if (btnText) {
      this.renderer.setStyle(iconElement, 'margin-right', '4px');
    }

    return iconElement;
  }

  /**
   * Renders and returns the element containing the button text
   * @param btnText - Text to render
   * @returns Text element
   */
  private renderBtnText(btnText: string): unknown {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const textElement = this.renderer.createElement('span');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const text = this.renderer.createText(btnText);

    this.renderer.appendChild(textElement, text);

    return textElement;
  }
}
