import { IUserGetAdmins } from '@model/interfaces/user.interface';

export type RBARolesType = 'application' | 'custom';

export const ADMIN_ROLES = {
  AccountAdmin: 'account_admin_title',
  SignatureManagementOwner: 'signature_management_owner_title',
  SignatureMarketingOwner: 'signature_marketing_owner_title'
};

export type UserGetAdvancedAdmins = IUserGetAdmins & {
  RolesAdmins: AdminRole[];
};

export type AdminRole = {
  Role: RBARole;
  roleId: string;
};

export type RBARole = {
  description: string;
  descriptionTag: string;
  id: string;
  title: string;
  titleTag: string;
  type: RBARolesType;
};
