import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { EBoxType } from 'src/app/model/enums/box-type.enum';
import { BoxComponent, IBoxComponentOptions } from '../box.class';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgClass, NgIf, NgStyle } from '@angular/common';

@Component({
  selector: 'mt-box',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, MtSvgComponent, NgStyle]
})
export class DefaultBoxComponent implements OnInit {
  @Input() cssText?: string;
  @Input() fontSize = '14px';
  @Input() icon?: string;
  @Input() iconColor?: string;
  @Input() iconSrc?: string;
  @Input() isFullWidth = false;
  @Input() klass?: string;
  @Input() parent?: IBoxComponentOptions;
  @Input() text!: string;
  @Input() translateTag?: string;
  @Input() type: EBoxType | string = EBoxType.NONE;

  options!: IBoxComponentOptions;

  constructor(private translateService: TranslocoService) {}

  ngOnInit(): void {
    if (this.translateTag) {
      this.text = this.translateService.translate(this.translateTag) as string;
    }

    const currentOptions: IBoxComponentOptions = Object.assign({}, this.parent, {
      icon: this.icon,
      iconColor: this.iconColor,
      iconSrc: this.iconSrc,
      isFullWidth: this.isFullWidth,
      text: this.text,
      translateTag: this.translateTag
    });

    const boxComponent = new BoxComponent(this.type, currentOptions);

    this.options = boxComponent.getOptions();
  }
}
