<div class="content {{ klass }}">
  <div class="othermethod">
    <div
      class="infobox {{ options.boxClass }}"
      [ngClass]="{ 'full-width-imp': options.isFullWidth }"
    >
      <div class="flex flex-center-all">
        <ng-container>
          <span
            *ngIf="options.icon"
            class="symbol {{ options.iconColor }}"
          ></span>
          <mt-svg
            *ngIf="!options.icon"
            size="25"
            class="{{ options.iconColor }}"
            [cssText]="cssText"
            [src]="options.iconSrc"
          ></mt-svg>
        </ng-container>
        <div class="text">
          <span
            [ngStyle]="{ 'font-size': fontSize }"
            [innerHTML]="options.text"
          ></span>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
