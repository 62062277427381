import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IPromptOptions } from 'src/app/services/alert/alert-service.interface';
import { NgIf } from '@angular/common';
import { DefaultBoxComponent } from '@molecules/boxes/default/default.component';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'mt-default-confirm-prompt',
  templateUrl: './default-confirm-prompt.component.html',
  styleUrls: ['./default-confirm-prompt.component.scss'],
  standalone: true,
  imports: [MtSvgComponent, DefaultBoxComponent, NgIf, ButtonComponent]
})
/**
 * Creates a default confirm prompt with a _Cancel_ and successful button,
 * where the latter is defined in the `parent` parameter.
 *
 * @returns the modal emits boolean values
 */
export class DefaultConfirmPromptModalComponent {
  /**
   * Holds the options needed to create the custom default confirm prompt.
   */
  @Input() parent!: IPromptOptions;

  constructor(public modal: NgbActiveModal) {}
}
