import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { catchError, map, Observable } from 'rxjs';
import { IResponseData, ResponseDataOrMessage } from 'src/app/model/interfaces/response.interface';
import {
  CalcBasePrices,
  CalcPriceResponse,
  ContractInformation,
  CustomerHasFreeSubscription,
  CustomerSubscriptionData,
  isAuthorizationsScopeInstance,
  isFreeSubscription,
  Product,
  ProductData,
  ProductDuration,
  SubscriptionInvoices,
  SubscriptionInvoicesAutomaticPayment,
  UserStatus
} from 'src/app/model/interfaces/payment.interface';
import { environment } from 'src/environments/environment';
import { GetOverallStatsResponse } from 'src/app/services/user/user-service.interface';
import { differenceInDays, format, parseISO } from 'date-fns';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AccountService } from '../account/account.service';
import { IMAILTASTIC_AUTHORIZATION_SCOPE } from '../account/account-service.interface';
import { SERVICE_DATA } from '@modules/booking/model/const/booking.const';

/**
 * Users slot's to calculate price based on given slot number
 * First 49 user has more amount then 50 to 249 etc..
 */
export const FIRST_SLOT_USERS = 49;
export const SECOND_SLOT_USERS = 249;
export const THIRD_SLOT_USERS = 499;

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  /**
   * User signup subscription trial days
   * @defaultValue 14
   */
  SUBSCRIPTION_TRIAL_DAYS = 14;

  constructor(
    private accountService: AccountService,
    private alert: AlertService,
    private authService: AuthService,
    private http: HttpClient,
    private operator: CustomOperators
  ) {}

  /**
   * Gets the subscription data for the customer via API or localstorage
   * @param accountId - The accountId
   * @returns Observable of CustomerSubscriptionData or CustomerHasFreeSubscription
   *
   */
  getCustomerSubscriptionData(accountId: string): Observable<CustomerSubscriptionData | CustomerHasFreeSubscription> {
    return this.http.get<ResponseDataOrMessage<CustomerSubscriptionData>>('/payment/subscription/saas').pipe(
      map(value => {
        this.accountService.removeSubscriptionDataCache();
        // Check if success not true
        if (!value.success) {
          throw new Error(this.alert.translateDataNotLoaded());
        }
        // Saving saas data into local storage
        if (!value.message && value.data) {
          value.data.accountId = accountId;
          this.accountService.setSubscriptionDataCache(value.data);
          return value.data;
        }
        return { hasFreeSubscription: true };
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Sets the minimal bookable amount of meta data
   * @param amount
   * @returns
   */
  setMinBookableAmountMetaData(amount: number): unknown {
    return this.http
      .post('/payment/setminbookablemetaamount', { minAmount: amount })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets all invoices from chargify if payment_collection_method is invoice
   * @returns List of invoice data
   */
  getInvoices(): Observable<SubscriptionInvoices[]> {
    return this.http
      .get<IResponseData<SubscriptionInvoices[]>>('/payment/invoices')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the invoices for customers with automatic payment method (paypal, creditcard)
   * @returns List of invoice data
   */
  getInvoicesForAutomaticPayment(): Observable<SubscriptionInvoicesAutomaticPayment[]> {
    return this.http
      .get<IResponseData<SubscriptionInvoicesAutomaticPayment[]>>('/payment/invoicesforautomaticpayment')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets all URLs with which the statements can be downloaded.
   * Statements are the invoices for automatic payment customers.
   * @returns
   */
  getStatements(): unknown {
    return this.http.get('/payment/statements').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Shows the invoice as a PDF in a new browser window.
   * @param invoiceId - Invoice id to open in new tab
   */
  downloadInvoice(invoiceId: string): void {
    window.open(
      `${environment.apiUrl}/payment/invoices/${invoiceId}?token=${this.authService.getToken()}`,
      'Download your invoice',
      'location=0,status=0,width=800,height=800'
    );
  }

  /**
   * Shows the statement as a PDF in a new browser window.
   * Statements are the invoices for automatic payment customers.
   * @param invoiceId
   * @returns
   */
  downloadStatement(invoiceId: string): void {
    // TODO implement function downloadStatement
  }

  /**
   * Synchronizes the current amount of employees in the mailtastic system
   * with the amount which is in the payment system. Uses the current plan and additions.
   * @returns
   */
  syncEmployees(): unknown {
    return this.http.post('/payment/sync', null).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets account data from isaac like adress and email of customer
   * @returns
   */
  getIsaacAccountData(): unknown {
    return this.http.get('/payment/customerdata').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Switches to a yearly billing interval but stays with the same product
   * @returns
   */
  switchToYearlyPlan(): void {
    // TODO implement function switchToYearlyPlan
  }

  /**
   * Switches the plans like from professional to business or from yearly account to monthly account
   * @param product - Product name
   * @param billingInterval - Interval type
   */
  switchPlan(product: Product, billingInterval: ProductDuration): Observable<void> {
    return this.http
      .put<IResponseData<void>>('/payment/changeproduct', { product, billingInterval })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get user slot number to calculate price
   * @param amountOfUsers - Per user amount
   * @returns Associated slot number
   */
  getUserSlotNumber(amountOfUsers: number): number {
    if (amountOfUsers > SECOND_SLOT_USERS) {
      return 3;
    } else if (amountOfUsers > FIRST_SLOT_USERS) {
      return 2;
    } else {
      return 1;
    }
  }

  /**
   * Calculates the prices for a given billing interval and the given users
   * @param plans - Object with all plan details
   * @param billingInterval - String for plan duration like 'yearly'
   * @param amountOfUsers - Per user amount
   * @returns Object of CalcPriceResponse
   */
  calcPrice(plans: CalcBasePrices, billingInterval: ProductDuration, amountOfUsers: number): CalcPriceResponse {
    // Base prices
    const firstSlotPriceBasicPerUser = plans.Basic[billingInterval].firstSlot.costPerUser;
    const secondSlotPriceBasicPerUser = plans.Basic[billingInterval].secondSlot.costPerUser;
    const thirdSlotPriceBasicPerUser = plans.Basic[billingInterval].thirdSlot.costPerUser;

    const firstSlotPriceAdvancedPerUser = plans.Advanced[billingInterval].firstSlot.costPerUser;
    const secondSlotPriceAdvancedPerUser = plans.Advanced[billingInterval].secondSlot.costPerUser;
    const thirdSlotPriceAdvancedPerUser = plans.Advanced[billingInterval].thirdSlot.costPerUser;

    const firstSlotPriceTargetPerUser = plans.Target[billingInterval].firstSlot.costPerUser;
    const secondSlotPriceTargetPerUser = plans.Target[billingInterval].secondSlot.costPerUser;
    const thirdSlotPriceTargetPerUser = plans.Target[billingInterval].thirdSlot.costPerUser;

    const basicMinBookableAmount = plans.Basic.minBookableAmount || 0;
    const advancedMinBookableAmount = plans.Advanced.minBookableAmount || 0;
    const targetMinBookableAmount = plans.Target.minBookableAmount || 0;

    // Hold total calc price per product
    const price = {
      basic: basicMinBookableAmount * firstSlotPriceBasicPerUser,
      basicPerUser: firstSlotPriceBasicPerUser,
      advanced: advancedMinBookableAmount * firstSlotPriceAdvancedPerUser,
      advancedPerUser: firstSlotPriceAdvancedPerUser,
      target: targetMinBookableAmount * firstSlotPriceTargetPerUser,
      targetPerUser: firstSlotPriceTargetPerUser
    };

    // Get user slot number to calculate price
    const slotNumber = this.getUserSlotNumber(amountOfUsers);

    // For basic plan
    if (amountOfUsers >= basicMinBookableAmount) {
      switch (slotNumber) {
        case 3:
          price.basicPerUser = thirdSlotPriceBasicPerUser;
          break;
        case 2:
          price.basicPerUser = secondSlotPriceBasicPerUser;
          break;
      }
      price.basic = amountOfUsers * price.basicPerUser;
    }

    // For advanced plan
    if (amountOfUsers >= advancedMinBookableAmount) {
      switch (slotNumber) {
        case 3:
          price.advancedPerUser = thirdSlotPriceAdvancedPerUser;
          break;
        case 2:
          price.advancedPerUser = secondSlotPriceAdvancedPerUser;
          break;
      }
      price.advanced = amountOfUsers * price.advancedPerUser;
    }

    // For target plan
    if (amountOfUsers >= targetMinBookableAmount) {
      switch (slotNumber) {
        case 3:
          price.targetPerUser = thirdSlotPriceTargetPerUser;
          break;
        case 2:
          price.targetPerUser = secondSlotPriceTargetPerUser;
          break;
      }
      price.target = amountOfUsers * price.targetPerUser;
    }

    // Round numbers
    const priceBasicFractional = (Math.round(price.basic * 100) / 100).toFixed(2);
    const priceAdvancedFractional = (Math.round(price.advanced * 100) / 100).toFixed(2);
    const priceTargetFractional = (Math.round(price.target * 100) / 100).toFixed(2);

    return {
      basePrices: plans,
      priceBasicPerUser: price.basicPerUser,
      firstSlotPriceBasicPerUser,
      secondSlotPriceBasicPerUser,
      thirdSlotPriceBasicPerUser,
      priceAdvancedPerUser: price.advancedPerUser,
      firstSlotPriceAdvancedPerUser,
      secondSlotPriceAdvancedPerUser,
      thirdSlotPriceAdvancedPerUser,
      priceTargetPerUser: price.targetPerUser,
      firstSlotPriceTargetPerUser,
      secondSlotPriceTargetPerUser,
      thirdSlotPriceTargetPerUser,
      priceBasic: Number(priceBasicFractional),
      priceAdvanced: Number(priceAdvancedFractional),
      priceTarget: Number(priceTargetFractional),
      formatted: {
        basic: {
          afterComma: priceBasicFractional.split('.')[1],
          beforeComma: priceBasicFractional.split('.')[0]
        },
        advanced: {
          afterComma: priceAdvancedFractional.split('.')[1],
          beforeComma: priceAdvancedFractional.split('.')[0]
        },
        target: {
          afterComma: priceTargetFractional.split('.')[1],
          beforeComma: priceTargetFractional.split('.')[0]
        }
      }
    };
  }

  /**
   * Gets the individual url to the customer billing portal
   * @returns URL
   */
  getCustomerPortalUrl(): Observable<string> {
    return this.http.get<IResponseData<string>>('/payment/customerportalurl').pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the subscription status
   * @param deferred
   * @returns
   */
  getSubscriptionStatus(deferred: unknown): void {
    // TODO implement function getSubscriptionStatus
  }

  /**
   * Get string to show on payment details
   * @param userCount - Number of users
   * @param price - Amount to show
   * @returns String like `30 User x 3,00 € / Month per User = 1.080,00 €`
   */
  getContractPriceDetailsToShow(userCount: number, price: number): string {
    const currencyPipe = new CurrencyPipe('de-DE');
    return `${userCount} ${this.alert.translate('licences')} x ${
      currencyPipe.transform(price / 12, 'EUR', 'symbol', '1.2-2') as string
    } / ${this.alert.translate('Month')} ${this.alert.translate('perUser')} = ${
      currencyPipe.transform(userCount * price, 'EUR', 'symbol', '1.2-2') as string
    }<br/>`;
  }

  /**
   * Get subscription contract details
   * @param subscription - Object of Saas
   * @param userStatus - Object of IUserStatus
   * @returns Contract information object
   */
  getContractInformation(subscription: CustomerSubscriptionData, userStatus: UserStatus): ContractInformation {
    let contractValue: number, contractPriceDetails: string;
    if (subscription.priceList && subscription.priceList.length === 3) {
      const priceData = this.calcPrice(
        SERVICE_DATA.plans,
        userStatus.billingInterval,
        userStatus.currentAmountOfLicenses
      );
      switch (userStatus.currentProduct) {
        case 'Basic':
          contractValue = priceData.priceBasic;
          break;
        case 'Advanced':
          contractValue = priceData.priceAdvanced;
          break;
        case 'Target':
          contractValue = priceData.priceTarget;
          break;
        default:
          contractValue = userStatus.monthlyPricePerUser * userStatus.currentAmountOfLicenses;
          break;
      }
      // Get user slot number to calculate price
      contractValue = contractValue * subscription.product.interval;

      // Get detailed price list to elaborate total amount
      const slotNumber = this.getUserSlotNumber(userStatus.currentAmountOfLicenses);
      switch (slotNumber) {
        case 3:
          contractPriceDetails = this.getContractPriceDetailsToShow(
            userStatus.currentAmountOfLicenses,
            subscription.priceList[2].unit_price
          );
          break;
        case 2:
          contractPriceDetails = this.getContractPriceDetailsToShow(
            userStatus.currentAmountOfLicenses,
            subscription.priceList[1].unit_price
          );
          break;
        default:
          contractPriceDetails = this.getContractPriceDetailsToShow(
            userStatus.currentAmountOfLicenses,
            subscription.priceList[0].unit_price
          );
          break;
      }
    } else {
      // Calc current contract value (total amount)
      contractValue =
        userStatus.monthlyPricePerUser * subscription.product.interval * userStatus.currentAmountOfLicenses;
      contractPriceDetails = this.getContractPriceDetailsToShow(
        userStatus.currentAmountOfLicenses,
        userStatus.monthlyPricePerUser * subscription.product.interval
      );
    }
    return { contractValue, contractPriceDetails };
  }

  /**
   * Checks if user has a subscription and if so checks remaining free seats
   * Checks the amountOfUsers, forceFlag and other options
   * @param state - Object of GetOverallStatsResponse
   * @param subscription - Object of logged in user subscription data
   * @returns Object of IUserStatus
   */
  getUserStatus(
    data: GetOverallStatsResponse | IMAILTASTIC_AUTHORIZATION_SCOPE,
    subscription: CustomerSubscriptionData | CustomerHasFreeSubscription
  ): UserStatus {
    let userStatus!: UserStatus;

    // Assign common object data to response object
    userStatus = {
      ...userStatus,
      forceAllow: data.forceAllow ? true : false, // Check force allow
      hasMultiSignature: data.hasMultiSignature === true,
      isCommunityEditionAccount: data.isCommunityEditionAccount === true ? true : false,
      isLocked: data.isLocked ? true : false, // Check trial is expired
      success: true
    };

    if (!isAuthorizationsScopeInstance(data)) {
      userStatus = {
        ...userStatus,
        mailPolicy: data.mailPolicy,
        forwardMailsTo: data.forwardMailsTo,
        amountOfFreeMembers: data.maxCommunityUsers, // Check allowed free users
        amountOfUsers: data.amountOfUsers // Amount of free members
      };
    }

    // For community account enabled trial
    // For trial accounts check not subscription and not isLocked then update remaining days trial
    const parsedBegin = parseISO(data.createdAt.toString());
    if (data.isCommunityEditionAccount === true) {
      userStatus.hasTestTime = true;
      userStatus.trialDaysLeft = 0;
    } else if (isFreeSubscription(subscription) && !data.isLocked) {
      userStatus.hasTestTime = true;

      // Calculate trial remaining days
      const parsedEnd = parseISO(format(new Date(), 'yyyy-MM-dd'));
      const amountOfDays = differenceInDays(parsedEnd, parsedBegin);
      userStatus.trialDaysLeft =
        amountOfDays < this.SUBSCRIPTION_TRIAL_DAYS ? this.SUBSCRIPTION_TRIAL_DAYS - amountOfDays : 0;
    } else {
      userStatus.hasTestTime = false;
    }

    // Check to signup with new angular after 30-01-2023
    userStatus.isUserFromAngularTs = parsedBegin >= parseISO('2023-01-31');

    if (isFreeSubscription(subscription)) {
      userStatus.hasSubscription = false;
    } else if (['expired', 'canceled'].includes(subscription.state)) {
      userStatus.state = subscription.state;
      userStatus.hasSubscription = false;
    } else {
      // Subscription is running
      userStatus.state = subscription.state;
      userStatus.hasSubscription = true;
      userStatus.interval = subscription.product.interval;

      if (subscription.customFields) {
        const maxEmpsInPeriod = subscription.customFields.find(info => info.name === 'MaxEmpsInPeriod');
        if (maxEmpsInPeriod) {
          userStatus.currentAmountOfLicenses = maxEmpsInPeriod.value;
        }
      }

      // Use component quantity when Max emps value is not set
      if (!userStatus.currentAmountOfLicenses) {
        userStatus.currentAmountOfLicenses = subscription.component.allocated_quantity;
      }

      // Determine price per user
      userStatus.monthlyPricePerUser = subscription.pricePerUser / subscription.product.interval;
      userStatus.hasTestTime = false;
      userStatus.billingInterval = subscription.product.interval === 1 ? 'monthly' : 'yearly';
      userStatus.priceList = subscription.priceList;
      userStatus.firstSlotUsers = FIRST_SLOT_USERS;
      userStatus.secondSlotUsers = SECOND_SLOT_USERS;
      userStatus.thirdSlotUsers = THIRD_SLOT_USERS;

      // Get product name;
      if (subscription.component.name.includes('Business')) {
        userStatus.currentProduct = 'Business';
      } else if (subscription.component.name.includes('Professional')) {
        userStatus.currentProduct = 'Professional';
      } else if (subscription.component.name.includes('ABM')) {
        userStatus.currentProduct = 'ABM';
      } else if (
        subscription.component.name.includes('Basic') ||
        subscription.component.name.includes('Email Signature Management')
      ) {
        userStatus.currentProduct = 'Basic';
      } else if (
        subscription.component.name.includes('Advanced') ||
        new RegExp('Email Signature Marketing \\d+Year').test(subscription.component.name)
      ) {
        userStatus.currentProduct = 'Advanced';
      } else if (
        subscription.component.name.includes('Target') ||
        subscription.component.name.includes('Email Signature Marketing Pro')
      ) {
        userStatus.currentProduct = 'Target';
      }

      // Calc current contract value and pricing structure
      const { contractValue, contractPriceDetails } = this.getContractInformation(subscription, userStatus);
      userStatus.contractValue = contractValue;
      userStatus.contractPriceDetails = contractPriceDetails;

      // Get amount of employees the customer is currently paying for
      const freeEmps = userStatus.currentAmountOfLicenses - userStatus.amountOfUsers;
      if (freeEmps > 0) {
        userStatus.amountOfFreeMembers = freeEmps;
      } else {
        userStatus.amountOfFreeMembers = 0;
      }
    }

    return userStatus;
  }

  /**
   * Gets the all plan/product data objects
   * @returns Objects of plans
   */
  getProducts(): Observable<ProductData> {
    return this.http.get<IResponseData<ProductData>>('/payment/productdata').pipe(this.operator.extractResponseData());
  }
}
