<div class="modal-content {{ parent.theme }}">
  <!-- Modal Header -->
  <div class="inner-header" class="{{ parent.headerColor }}">
    <!-- Custom Headers -->
    <ng-container *ngIf="!parent?.headline">
      <!-- Just Modal Header -->
      <div #modalHeader *ngIf="showModalHeader">
        <ng-content select="[modal-header]"></ng-content>
      </div>
      <ng-content
        select="[modal-header]"
        *ngIf="!parent?.headline"
      ></ng-content>

      <!-- Modal Header with Close Button -->
      <div
        class="flex-center-space-between"
        #modalHeaderClose
        *ngIf="showModalHeaderClose"
      >
        <ng-content select="[modal-header-close]"> </ng-content>
        <ng-container *ngTemplateOutlet="closeButton"></ng-container>
      </div>
    </ng-container>

    <!-- Default Header -->
    <div
      class="flex-center-space-between"
      *ngIf="parent?.headline || parent?.closeBtn"
    >
      <div
        class="title"
        data-hj-suppress
        *ngIf="parent?.headline"
        [innerHTML]="parent.headline"
      ></div>

      <!-- Close Button -->
      <ng-container *ngIf="parent?.closeBtn">
        <ng-container *ngTemplateOutlet="closeButton"></ng-container>
      </ng-container>
    </div>
  </div>

  <!-- Modal Body -->
  <div class="inner-body modal-body">
    <ng-content select="[modal-body]"></ng-content>
  </div>

  <!-- Line -->
  <div class="line" role="separator"></div>

  <!-- Modal Footer -->
  <div class="inner-footer">
    <!-- Custom Modal Footer -->
    <div #modalFooter *ngIf="showModalFooter">
      <ng-content select="[modal-footer]"></ng-content>
    </div>

    <!-- Modal Footer Left -->
    <div class="inner-footer-left" #modalFooterLeft *ngIf="showModalFooterLeft">
      <ng-content select="[modal-footer-left]"></ng-content>
    </div>

    <!-- Modal Footer Right -->
    <div
      class="inner-footer-right"
      #modalFooterRight
      *ngIf="showModalFooterRight"
    >
      <ng-content select="[modal-footer-right]"></ng-content>
    </div>
  </div>
</div>

<!-- Close Button Template -->
<ng-template #closeButton>
  <div
    class="cursor-pointer close"
    (click)="modal.close()"
    data-cy="modal-close-icon"
  >
    <mt-svg
      class="x-button"
      src="assets/images/modals/close_other_svg.svg"
    ></mt-svg>
  </div>
</ng-template>
