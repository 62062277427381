export const INTERCOM_DATA = {
  assigned_campaign_to_group: 'Assigned campaign to one group',
  assigned_signature_to_group: 'Assigned signature to one group',
  campaign_changed: 'Campaign changed',
  campaign_created: 'Campaign created',
  campaign_modified: 'Campaign modified',
  company_info_changed: 'Changed company info data',
  employee_added: 'Employee added',
  employee_removed: 'Employee(s) removed',
  event_created: 'Event created',
  event_modified: 'Event modified',
  group_created: 'Created group',
  group_modified: 'Group modified',
  guide_section1_1_completed: 'Get to know Cognism Signatures: Step1 completed',
  guide_section1_2_completed: 'Get to know Cognism Signatures: Step2 completed',
  guide_section2_1_completed: 'Complete Account Setup: Step1 completed',
  guide_section3_1_completed: 'Create your Signature: Step1 completed',
  guide_section3_2_completed: 'Create your Signature: Step2 completed',
  guide_section3_3_completed: 'Create your Signature: Step3 completed',
  guide_section3_4_completed: 'Create your Signature: Step4 completed',
  guide_section4_1_completed: 'Install your Signature: Step1 completed',
  guide_section4_2_completed: 'Install your Signature: Step2 completed',
  guide_section5_1_completed: 'Import your Colleagues to Cognism Signatures: Step1 completed',
  guide_section5_2_completed: 'Import your Colleagues to Cognism Signatures: Step2 completed',
  guide_section5_3_completed: 'Import your Colleagues to Cognism Signatures: Step3 completed',
  invitation_testmail_sent_to_admin: 'Invitation testmail sent to admin',
  notification_created: 'Notification created',
  nps_survey_changed: 'Closed nps survey',
  nps_survey_completed: 'Completed nps survey',
  nps_survey_viewd: 'Viewed nps survey',
  resend_invitation: 'Resent invitation',
  searched_for_articles: 'Searched for articles',
  signature_created: 'Signature created',
  signature_modified: 'Signature modified',
  signature_rolledout: 'Signature rolled out',
  signature_updated: 'Signature updated',
  user_data_changed: 'User data changed',
  user_info_data_changed: 'Changed user info data',
  user_login: 'user logged in',
  viewed_setup_guide: 'Viewed setup guide'
};
