<div class="input-group searchfield {{ klass }}">
  <input
    [ngModel]="searchFieldText"
    (ngModelChange)="onSearchFieldChange($event)"
    (keypress)="onSearchFieldKeypress($event)"
    type="text"
    class="form-control input-sm nooutline"
    [placeholder]="inputPlaceholder"
    data-cy="search-field"
  />
  <span
    (click)="onSearchButtonClick($event)"
    class="input-group-addon glyphicon glyphicon-search"
  ></span>
</div>
