export interface ButtonStyle {
  color: string;
  outline: boolean;
  outlineColor: string;
}

export type ButtonColor =
  | 'danger'
  | 'default'
  | 'green'
  | 'legacy_primary'
  | 'primary'
  | 'primary_darker'
  | 'success'
  | 'trans_gruen'
  | 'trans_rot'
  | 'white';

export class ButtonService {
  /**
   * Creates the style from the color.
   * @remarks first instance in kebab-case is ALWAYS the color name.
   *          Each further property uses the `key_value` syntax with the `value` being a color global variable.
   * @example `transparent-outline_gruen` will create a transparent button with green outline.
   *
   * @param color - either color name or kebab-case with first being color name
   * @returns style of button
   */
  static createStyleFromColor(color: string): ButtonStyle {
    const style: ButtonStyle = {
      color: 'mt-button-default',
      outline: false,
      outlineColor: 'var(--default-outline-color)'
    };

    if (color !== '') {
      if (color.split('-').length > 1) {
        const properties = color.split('-');
        style.outline = color.includes('outline');
        style.color = `mt-button-${properties[0]}`;
        if (style.outline && color.includes('outline_')) {
          style.outlineColor = `var(--${properties[1].split('_')[1]})`;
        }
      } else {
        style.color = `mt-button-${color}`;
      }
    }

    return style;
  }
}
