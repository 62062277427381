import { UpperCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { FilterPipe } from 'src/app/shared/pipes/filter/filter.pipe';
import { FormsModule } from '@angular/forms';

/**
 * @private
 * Accepted pipes for `mt-search` component.
 */
const PIPES: { [name: string]: PipeTransform } = {
  filter: new FilterPipe(),
  uppercase: new UpperCasePipe() // currently used for example purposes
};

@Component({
  selector: 'mt-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [FormsModule]
})
export class MtSearchComponent implements OnInit {
  //#region I/O
  /**
   * Disables the need to use the ENTER key to emit the search text change.
   * @defaultValue false
   */
  @Input() disableEnter = false;

  /**
   * Applies the class onto the first child.
   */
  @Input() klass?: string;

  /**
   * Sets the length of the search text needs to be before emitting the change.
   * @defaultValue 1
   */
  @Input() minLength = 1;

  /**
   * Applies these pipes onto the search text.
   *
   * @remarks
   *
   * Allowed pipes are defined in the constant {@link PIPES} of this file.
   *
   * @example
   * ```
   * <mt-search [pipes]="[{pipe:'uppercase'}]" (search)="search($event)"></mt-search>
   * ```
   */
  @Input() pipes: { pipe: string; args?: unknown[] }[] = [];

  /**
   * Placeholder as translate tag.
   */
  @Input() placeholder?: string;

  /**
   * Emits the change of the search text.
   */
  @Output() search = new EventEmitter<string>();
  //#endregion

  //#region PUBLIC PROPERTIES
  /**
   * Sets the input placeholder defined by {@link placeholder}.
   * @defaultValue `this.translate.translate('Search') as string`
   */
  inputPlaceholder = '';

  /**
   * Binds to the input element.
   */
  searchFieldText!: string;

  /**
   * The text typed into the input field.
   */
  searchText = '';
  //#endregion

  //#region PRIVATE PROPERTIES
  /**
   * Gives permission if text should be emitted.
   * @private
   */
  private allowEmit = false;

  /**
   * Holds last time stamp to prevent long-press of ENTER
   * @private
   */
  private lastTimeStamp = 0;
  //#endregion

  constructor(private translate: TranslocoService) {}

  //#region LIFE CYCLE
  ngOnInit(): void {
    if (!this.placeholder) {
      this.inputPlaceholder = this.translate.translate('Search') as string;
    } else {
      this.inputPlaceholder = this.translate.translate(this.placeholder) as string;
    }
  }
  //#endregion

  //#region PUBLIC
  /**
   * Emits the search text.
   * @param text - text that should be emitted
   * @param allow - if the `text` should be emitted
   */
  emitSearchText(text: string, allow: boolean): void {
    if (allow) {
      this.allowEmit = false;
      this.search.emit(this.applyPipes(text));
    }
  }

  /**
   * Emits the search text when the search icon is clicked.
   * @param ev -
   */
  onSearchButtonClick(ev: MouseEvent): void {
    if (ev.type === 'click') {
      this.search.emit(this.applyPipes(this.searchText));
    }
  }

  /**
   * Tries to emit the text, iff {@link allowEmit} condition is true.
   * @param text - HTMLInputElement value change
   */
  onSearchFieldChange(text: string): void {
    this.searchText = text;

    // resets the search if empty
    if (this.searchText.length === 0) {
      this.search.emit(this.searchText);
    }

    if (this.minLength <= this.searchText.length) {
      if (this.disableEnter) {
        this.allowEmit = this.disableEnter;
      }

      this.emitSearchText(this.searchText, this.allowEmit);
    }
  }

  /**
   * Checks if key ENTER was pressed and then allows emitting.
   * @param ev -
   */
  onSearchFieldKeypress(ev: KeyboardEvent): void {
    // prevents long-hold of the Enter key
    const minTimestampInterval = 100;
    if (ev.timeStamp - this.lastTimeStamp < minTimestampInterval) {
      return;
    }

    if (this.minLength <= this.searchText.length) {
      if (ev.type === 'keypress' && ev.key === 'Enter') {
        this.lastTimeStamp = ev.timeStamp;
        this.allowEmit = true;
      }

      this.emitSearchText(this.searchText, this.allowEmit);
    }
  }
  //#endregion

  //#region PRIVATE
  /**
   * Applies all pipes passed by `pipes`.
   * If no pipes were defined, then no changes will happen.
   * @param text - the text that the pipes should be applied onto
   * @returns the pipe applied text
   * @private
   */
  private applyPipes(text: string): string {
    let appliedFilterText = text;
    if (this.pipes.length !== 0) {
      for (const filter of this.pipes) {
        if (PIPES[filter.pipe] !== undefined) {
          appliedFilterText = PIPES[filter.pipe].transform(appliedFilterText, filter.args) as string;
        }
      }
      this.searchFieldText = appliedFilterText;
    }
    return appliedFilterText;
  }
  //#endregion
}
