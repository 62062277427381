import { EBoxType } from 'src/app/model/enums/box-type.enum';

export interface IBoxComponentOptions {
  boxClass?: string;
  icon?: string;
  iconColor: string;
  iconSrc: string;
  isFullWidth?: boolean;
  text?: string;
  translateTag?: string;
  type: EBoxType;
}

export class BoxComponent {
  _type: EBoxType;
  _options: IBoxComponentOptions;

  static getIcon(type: EBoxType | string): string {
    const currentType = typeof type === 'string' ? BoxComponent.toType(type) : type;

    switch (currentType) {
      case EBoxType.ERROR:
      case EBoxType.WARN:
        return 'alert';
      case EBoxType.INFO:
        return 'info-sign';
      case EBoxType.SUCCESS:
        return 'check';
      default:
        return '';
    }
  }

  static setupDefaultOptions(type: EBoxType | string): IBoxComponentOptions {
    const currentType = typeof type === 'string' ? BoxComponent.toType(type) : type;

    switch (currentType) {
      // case EBoxType.ALERT:
      //   return {
      //     boxClass: 'infobox-info',
      //     iconColor: 'mt-info_blau',
      //     iconSrc: 'assets/images/modals/important_modal_svg.svg',
      //     type: currentType
      //   };
      case EBoxType.ERROR:
        return {
          boxClass: 'infobox-error',
          iconColor: 'mt-info_rot',
          iconSrc: 'assets/images/modals/warning_modal_svg.svg',
          type: currentType
        };
      case EBoxType.INFO:
        return {
          boxClass: 'infobox-info',
          iconColor: 'mt-info_blau',
          iconSrc: 'assets/images/modals/info_modal_svg.svg',
          type: currentType
        };
      case EBoxType.SUCCESS:
        return {
          boxClass: 'infobox-success',
          iconColor: 'mt-info_blau',
          iconSrc: 'assets/images/modals/confirmation_modal_svg.svg',
          type: currentType
        };
      case EBoxType.WARN:
        return {
          boxClass: 'infobox-warn',
          iconColor: 'mt-info_blau',
          iconSrc: 'assets/images/modals/warning_modal_svg.svg',
          type: currentType
        };
      default:
        return {
          iconColor: '',
          iconSrc: '',
          type: currentType
        };
    }
  }

  static toType(type: string): EBoxType {
    switch (type) {
      case 'alert':
        return EBoxType.ALERT;
      case 'error':
        return EBoxType.ERROR;
      case 'info':
        return EBoxType.INFO;
      case 'success':
        return EBoxType.SUCCESS;
      case 'warn':
        return EBoxType.WARN;
      default:
        return EBoxType.NONE;
    }
  }

  constructor(type: EBoxType | string, options?: IBoxComponentOptions) {
    this._type = typeof type === 'string' ? BoxComponent.toType(type) : type;

    const currentOptions = BoxComponent.setupDefaultOptions(type);

    this._options = this.setupParamOptions(currentOptions, options);
    if (typeof options?.icon !== 'undefined') {
      this.updateIcon(options.icon);
    }
  }

  getOptions(): IBoxComponentOptions {
    return this._options;
  }

  private setupParamOptions(
    currentOptions: IBoxComponentOptions,
    options?: IBoxComponentOptions
  ): IBoxComponentOptions {
    return {
      boxClass: typeof options?.boxClass !== 'undefined' ? options?.boxClass : currentOptions.boxClass,
      icon: typeof options?.icon !== 'undefined' ? options?.icon || '' : currentOptions.icon,
      iconColor: typeof options?.iconColor !== 'undefined' ? options?.iconColor || '' : currentOptions.iconColor,
      iconSrc: typeof options?.iconSrc !== 'undefined' ? options?.iconSrc || '' : currentOptions.iconSrc,
      isFullWidth:
        typeof options?.isFullWidth !== 'undefined' ? options?.isFullWidth || false : currentOptions.isFullWidth,
      text: typeof options?.text !== 'undefined' ? options?.text || '' : currentOptions.text,
      translateTag:
        typeof options?.translateTag !== 'undefined' ? options?.translateTag || '' : currentOptions.translateTag,
      type: typeof options?.type !== 'undefined' ? options?.type || this._type : currentOptions.type
    };
  }

  private updateIcon(icon: string): void {
    this._options.iconColor = !icon.includes('glyphicon') ? `glyphicon glyphicon-${icon}` : `glyphicon ${icon}`;
  }
}
