<ng-container *transloco="let translate">
  <div data-cy="empty-state">
    <!-- button -->
    @if (showEmpty && !loadingPromise) {
      <div class="flex createBtn">
        @if (buttonText(); as text) {
          @if (text.length) {
            @if (!requiredClaim) {
              <button
                mt-button
                data-cy="empty-state-button"
                [disabled]="disabled"
                [text]="text"
                [icon]="'plus'"
                ngbTooltip="{{
                  translate(disabled && disableTooltip ? disableTooltip : '')
                }}"
                placement="top"
                tooltipClass="status-info-tooltip"
                (click)="clickAction()"
              ></button>
            } @else {
              @if(requiredClaims && requiredClaims.length > 0) {
                <button
                  mt-button
                  mt-claim-authorization
                  [requiredClaims]="requiredClaims"
                  data-cy="empty-state-button"
                  [disabled]="disabled"
                  [text]="text"
                  [icon]="'plus'"
                  ngbTooltip="{{
                    translate(disabled && disableTooltip ? disableTooltip : '')
                  }}"
                  placement="top"
                  tooltipClass="status-info-tooltip"
                  (click)="clickAction()"
                ></button>
              } @else {
              <button
                mt-button
                mt-claim-authorization
                [requiredClaim]="requiredClaim"
                data-cy="empty-state-button"
                [disabled]="disabled"
                [text]="text"
                [icon]="'plus'"
                ngbTooltip="{{
                  translate(disabled && disableTooltip ? disableTooltip : '')
                }}"
                placement="top"
                tooltipClass="status-info-tooltip"
                (click)="clickAction()"
              ></button>
              }
            }
          }
        }
      </div>

      <div class="emptystate text-center">
        <img
          alt="svg icon"
          src="{{ svgSource }}"
          class="emptystate__img {{ emptystateImgClass }} {{ iconColor }}"
        />
        <div class="rightside">
          <!-- title -->
          <div
            class="emptystate__title {{ emptystateTitleClass }} {{
              titleColor
            }}"
            [innerHtml]="translate(hltitle)"
          ></div>
          <!-- subtitle -->
          <div
            class="emptystate__subtitle {{ emptystateSubitleClass }} {{
              subtitleColor
            }}"
            [innerHtml]="translate(subtitle)"
          ></div>
        </div>
      </div>
    }

    @if (loadingPromise) {
      <div class="loadingstate text-center">
        <!-- spinner -->
        <div class="spinner-border" role="status"></div>
        <!-- icon -->
        <div class="martop20">
          <div class="rightside">
            <!-- title -->
            <div class="loadingstate__title {{ loadingTitleClass }}">
              {{ translate(loaderTitle) }}
            </div>
            <!-- subtitle -->
            <div class="loadingstate__subtitle {{ loadingSubitleClass }}">
              {{ translate(loaderSubTitle) }}
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</ng-container>
