import { Injectable } from '@angular/core';
import { filter, from, Observable, take } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { EBoxType } from 'src/app/model/enums/box-type.enum';

// Modals
import { CustomModalComponent } from 'src/app/shared/components/molecules/modals/custom/custom-modal.component';
import { ICustomModalOptions, IDefaultSimpleMessageOptions, IPromptOptions } from './alert-service.interface';
import { DefaultConfirmPromptModalComponent } from '@shared/components/organisms/modals/default/default-confirm-prompt/default-confirm-prompt.component';
import { DefaultSimpleMessageComponent } from '@shared/components/organisms/modals/default/default-simple-message/default-simple-message.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private modalService: NgbModal, private translateService: TranslocoService) {}

  /**
   * Opens a custom modal.
   * @param options - additional options for the custom modal
   * @returns promise from the custom modal
   */
  customModal<T>(options?: ICustomModalOptions): Promise<T> {
    const modalRef = this.modalService.open(CustomModalComponent, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.parent = options;

    return modalRef.result as Promise<T>;
  }

  /**
   * Prints `console.debug(...)`
   * @param error - error message for debugging
   */
  debugFailedModal(error: string): void {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    console.debug(`Modal ${error} at ${new Date()}`);
  }

  //#region DEFAULT
  /**
   * @deprecated This method is deprecated - Use `defaultConfirmationPrompt` as observable
   * Opens a default confirm modal.
   * @param text - as translate tag
   * @param type - the style
   * @defaultValue `EBoxType.INFO`
   * @param headline - as translate tag
   * @defaultValue `translateService.translate('areYouSure') as string`
   * @param confirmBtn - as translate tag
   * @defaultValue `translateService.translate('integration_details2_errorGO_o365_owa') as string`
   * @param showCloseBtn - as boolean for show close button or not
   * @defaultValue `true`
   * @returns confirmation of the prompt
   */
  defaultConfirmPrompt(
    text: string,
    type?: EBoxType,
    headline?: string,
    confirmBtn?: string,
    showCloseBtn = true
  ): Promise<boolean> {
    const options: IPromptOptions = { text, type: EBoxType.NONE };
    options.confirmBtn = confirmBtn
      ? this.translateService.translate(confirmBtn)
      : this.translateService.translate('integration_details2_errorGO_o365_owa');
    options.headline = headline
      ? this.translateService.translate(headline)
      : this.translateService.translate('areYouSure');
    options.type = type ? type : EBoxType.INFO;
    options.showCloseBtn = showCloseBtn;

    const modalRef = this.modalService.open(DefaultConfirmPromptModalComponent, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.parent = options;

    return modalRef.result as Promise<boolean>;
  }

  /**
   * @deprecated This method is deprecated - Use `defaultErrorMessageV2` as observable
   * Opens a default error message modal.
   * @param text - if needed translate `text` before hand
   * @param headline - headline of modal
   * @defaultValue `translateService.translate('thatDidNowWork') as string`
   * @returns Promise from `defaultSimpleMessage` modal
   */
  defaultErrorMessage<T>(text: string, headline?: string): Promise<T> {
    const options: IDefaultSimpleMessageOptions = {
      bodyText: text,
      titleText: headline ? headline : this.translateService.translate('thatDidNowWork'),
      type: EBoxType.ERROR
    };

    const modalRef = this.modalService.open(DefaultSimpleMessageComponent, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.parent = options;

    return modalRef.result as Promise<T>;
  }

  /**
   * Opens a default error message modal.
   * @param text - if needed translate `text` before hand
   * @param headline - headline of modal
   * @defaultValue `translateService.translate('thatDidNowWork') as string`
   * @returns Observable with boolean value
   */
  defaultErrorMessageV2(text: string, headline?: string): Observable<boolean> {
    const options: IDefaultSimpleMessageOptions = {
      bodyText: text,
      titleText: headline ? headline : this.translateService.translate('thatDidNowWork'),
      type: EBoxType.ERROR
    };

    const modalRef = this.modalService.open(DefaultSimpleMessageComponent, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.parent = options;

    return from(modalRef.result as Promise<boolean>).pipe(take(1));
  }

  /**
   * @deprecated This method is deprecated - Use `defaultInfoMessageV2` as observable
   * Opens a default info message modal.
   * @param text - if needed translate `text` before hand
   * @param headline - headline of modal
   * @defaultValue `translateService.translate('Information') as string`
   * @returns Promise from `defaultSimpleMessage` modal
   */
  defaultInfoMessage<T>(text: string, headline?: string): Promise<T> {
    const options: IDefaultSimpleMessageOptions = {
      bodyText: text,
      titleText: headline ? headline : this.translateService.translate('Information'),
      type: EBoxType.INFO
    };

    const modalRef = this.modalService.open(DefaultSimpleMessageComponent, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.parent = options;

    return modalRef.result as Promise<T>;
  }

  /**
   * Opens a default info message modal.
   * @param text - if needed translate `text` before hand
   * @param headline - headline of modal
   * @defaultValue `translateService.translate('Information') as string`
   * @returns Observable with boolean value
   */
  defaultInfoMessageV2(text: string, headline?: string): Observable<boolean> {
    const options: IDefaultSimpleMessageOptions = {
      bodyText: text,
      titleText: headline ? headline : this.translateService.translate('Information'),
      type: EBoxType.INFO
    };

    const modalRef = this.modalService.open(DefaultSimpleMessageComponent, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.parent = options;

    return from(modalRef.result as Promise<boolean>).pipe(take(1));
  }

  /**
   * @deprecated This method is deprecated - Use `defaultSuccessMessageV2` as observable
   * Opens a default success message modal.
   * @param text - if needed translate `text` before hand
   * @param headline - headline of modal
   * @param btnText - btnText of modal
   * @param showConfetti - showConfetti of modal
   * @defaultValue `translateService.translate('done') as string`
   * @returns Promise from `defaultSimpleMessage` modal
   */
  defaultSuccessMessage<T>(text: string, headline?: string, btnText?: string, showConfetti = false): Promise<T> {
    const options: IDefaultSimpleMessageOptions = {
      bodyText: text,
      btnText: btnText,
      titleText: headline ? headline : this.translateService.translate('done'),
      type: EBoxType.SUCCESS
    };

    const modalRef = this.modalService.open(DefaultSimpleMessageComponent, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.parent = options;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.showConfetti = showConfetti;

    return modalRef.result as Promise<T>;
  }

  /**
   * Opens a default success message modal.
   * @param text - if needed translate `text` before hand
   * @param headline - headline of modal
   * @param btnText - btnText of modal
   * @param showConfetti - showConfetti of modal
   * @param visibleInMobile - Should be visible in mobile or not
   * @defaultValue `translateService.translate('done') as string`
   * @returns Observable from `defaultSimpleMessage` modal
   */
  defaultSuccessMessageV2<T>(
    text: string,
    headline?: string,
    btnText?: string,
    showConfetti = false,
    visibleInMobile = false
  ): Observable<T> {
    const options: IDefaultSimpleMessageOptions = {
      bodyText: text,
      btnText: btnText,
      titleText: headline ? headline : this.translateService.translate('done'),
      type: EBoxType.SUCCESS
    };

    const modalRef = this.modalService.open(DefaultSimpleMessageComponent, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: `mt_modal ${visibleInMobile ? 'mobileVersion' : ''}`
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.parent = options;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.showConfetti = showConfetti;

    return from(modalRef.result as Promise<T>).pipe(take(1));
  }
  //#endregion

  //#region TRANSLATE
  /**
   * Translates the `text`.
   * @param text - as translate tag
   * @returns the translated `text`
   */
  translate(text: string): string {
    if (text === '') return '';

    return this.translateService.translate(text);
  }

  /**
   * Translates 'DATEN_NICHT_GELADEN' and appends `error`.
   * @param error - object to be JSON.stringify
   * @returns complete error message
   */
  translateDataNotLoaded(error?: unknown): string {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const appendText = error ? ` ${JSON.stringify(error)}` : '';
    return `${this.translate('DATEN_NICHT_GELADEN')}${appendText}`;
  }

  /**
   * Translates 'TECHNISCHER_FEHLER' and appends `error`.
   * @param error - object to be JSON.stringify
   * @returns complete error message
   */
  translateTechnicalError(error?: unknown): string {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const appendText = error ? ` ${JSON.stringify(error)}` : '';
    return `${this.translate('TECHNISCHER_FEHLER')}${appendText}`;
  }

  /**
   * Translates 'CHANGES_SAVED_SUCCESSFULLY'
   * @returns Changes saved successfully text translated
   */
  translateChangesSaved(): string {
    return this.translate('CHANGES_SAVED_SUCCESSFULLY');
  }
  //#endregion

  //#region DEFAULT using observables
  // Prompts will be handled using observables
  /**
   * Opens a default confirm modal.
   * @param text - as translate tag
   * @param type - the style
   * @defaultValue `EBoxType.INFO`
   * @param headline - as translate tag
   * @defaultValue `translateService.translate('areYouSure') as string`
   * @param confirmBtn - as translate tag
   * @defaultValue `translateService.translate('integration_details2_errorGO_o365_owa') as string`
   * @param showCloseBtn - as boolean for show close button or not
   * @defaultValue `true`
   * @returns confirmation of the prompt
   */
  defaultConfirmationPrompt(
    text: string,
    type?: EBoxType,
    headline?: string,
    confirmBtn?: string,
    showCloseBtn = true
  ): Observable<boolean> {
    const options: IPromptOptions = { text, type: EBoxType.NONE };
    options.confirmBtn = confirmBtn
      ? this.translateService.translate(confirmBtn)
      : this.translateService.translate('integration_details2_errorGO_o365_owa');
    options.headline = headline
      ? this.translateService.translate(headline)
      : this.translateService.translate('areYouSure');
    options.type = type ? type : EBoxType.INFO;
    options.showCloseBtn = showCloseBtn;
    const modalRef = this.modalService.open(DefaultConfirmPromptModalComponent, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.parent = options;

    return from(modalRef.result as Promise<boolean>).pipe(
      take(1),
      filter(confirm => !!confirm)
    );
  }

  /**
   * Opens a coming soon modal.
   * @param text - As translate tag
   * @returns Modal close as a boolean
   */
  comingSoonPrompt(text?: string): Observable<boolean> {
    text = text ?? 'coming_soon_sl';
    const options: IPromptOptions = { text, type: EBoxType.NONE };
    options.confirmBtn = this.translateService.translate('Close');
    options.headline = this.translateService.translate('coming_soon_hl');

    options.type = EBoxType.INFO;
    options.showCloseBtn = false;
    const modalRef = this.modalService.open(DefaultConfirmPromptModalComponent, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.parent = options;

    return from(modalRef.result as Promise<boolean>).pipe(take(1));
  }

  /**
   * Opens a default confirm modal without filter of any response.
   * @param text - as translate tag
   * @param type - the style
   * @defaultValue `EBoxType.INFO`
   * @param headline - as translate tag
   * @defaultValue `translateService.translate('areYouSure') as string`
   * @param confirmBtn - as translate tag
   * @defaultValue `translateService.translate('integration_details2_errorGO_o365_owa') as string`
   * @param showCloseBtn - as boolean for show close button or not
   * @defaultValue `true`
   * @returns confirmation of the prompt
   */
  defaultConfirmationPromptWithoutFiltering(
    text: string,
    type?: EBoxType,
    headline?: string,
    confirmBtn?: string,
    showCloseBtn = true
  ): Observable<boolean> {
    const options: IPromptOptions = { text, type: EBoxType.NONE };
    options.confirmBtn = confirmBtn
      ? this.translateService.translate(confirmBtn)
      : this.translateService.translate('integration_details2_errorGO_o365_owa');
    options.headline = headline
      ? this.translateService.translate(headline)
      : this.translateService.translate('areYouSure');
    options.type = type ? type : EBoxType.INFO;
    options.showCloseBtn = showCloseBtn;

    const modalRef = this.modalService.open(DefaultConfirmPromptModalComponent, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.parent = options;

    return from(modalRef.result as Promise<boolean>).pipe(take(1));
  }
}
