import { Injectable } from '@angular/core';
import { UtmSignUp, UtmSignUpFirst } from '@services/user/user-service.interface';
import { CookieService } from 'ngx-cookie-service';
import { SourcebusterCookieKey } from './sourcebuster-cookie.interface';

@Injectable({
  providedIn: 'root'
})
export class SourcebusterCookieService {
  constructor(private cookieService: CookieService) {}

  /**
   * Gets the sourcebuster cookies revelant for UTM.
   * @returns the cookies as an object
   */
  parseSourcebusterCookies(): UtmSignUp & UtmSignUpFirst {
    const current = this.cookieService.get('sbjs_current');
    const first = this.cookieService.get('sbjs_first');
    const url = new URL(document.URL);

    if (!first && url.searchParams.toString().length > 0) {
      return this.parseUtmParams(url);
    }

    // parses sbjs_current cookie
    const parseCurrent = this.parseSourcebusterCookieCurrent(this.decodeSourcebusterCookie(current));

    // parses sbjs_first cookie
    const parseFirst = this.parseSourcebusterCookieFirst(this.decodeSourcebusterCookie(first));

    return { ...parseCurrent, ...parseFirst };
  }

  /**
   * Decodes the specific sourcebuster cookie.
   * @param cookie - cookie to decode
   * @returns decoded cookie
   */
  private decodeSourcebusterCookie(cookie: string): Map<SourcebusterCookieKey, string> {
    const keyVals = new Map<SourcebusterCookieKey, string>();
    // catch empty string case for unnecessary further processing
    if (cookie === '') {
      return keyVals;
    }

    const splitted = cookie.split('|||');
    // parses cookies to a Map for easier further processing
    splitted.forEach(keyVal => {
      const parser = keyVal.split('=');
      if (parser.length === 2) {
        keyVals.set(parser[0] as SourcebusterCookieKey, parser[1] ?? '');
      }
    });

    return keyVals;
  }

  /**
   * Parses the sourcebuster cookie `sbjs_current`.
   * @param cookie - cookie to parse
   * @returns cookie as UTM object
   */
  private parseSourcebusterCookieCurrent(cookie: Map<SourcebusterCookieKey, string>): UtmSignUp {
    return {
      campaign: cookie.get('cmp') ?? '',
      content: cookie.get('cnt') ?? '',
      medium: cookie.get('mdm') ?? '',
      source: cookie.get('src') ?? '',
      term: cookie.get('trm') ?? ''
    };
  }

  /**
   * Parses the sourcebuster cookie `sbjs_first`.
   * @param cookie - cookie to parse
   * @returns cookie as UTM object
   */
  private parseSourcebusterCookieFirst(cookie: Map<SourcebusterCookieKey, string>): UtmSignUpFirst {
    return {
      campaignFirst: cookie.get('cmp') ?? '',
      contentFirst: cookie.get('cnt') ?? '',
      mediumFirst: cookie.get('mdm') ?? '',
      sourceFirst: cookie.get('src') ?? '',
      termFirst: cookie.get('trm') ?? ''
    };
  }

  /**
   * Parses the UTM params in the URL.
   * @param url - URL to check
   * @returns UTM params as UTM object
   */
  private parseUtmParams(url: URL): UtmSignUp & UtmSignUpFirst {
    return {
      campaignFirst: url.searchParams.get('utm_campaign') ?? '',
      contentFirst: url.searchParams.get('utm_content') ?? '',
      mediumFirst: url.searchParams.get('utm_medium') ?? '',
      sourceFirst: url.searchParams.get('utm_source') ?? '',
      termFirst: url.searchParams.get('utm_term') ?? '',
      campaign: '',
      content: '',
      medium: '',
      source: '',
      term: ''
    };
  }
}
