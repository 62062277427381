import { IMAILTASTIC_AUTHORIZATION_SCOPE } from 'src/app/services/account/account-service.interface';

export type Product = 'Basic' | 'Advanced' | 'Target' | 'Professional' | 'Business' | 'ABM';
export type ProductDuration = 'monthly' | 'yearly';
export type ProductLinkType = 'automatic' | 'invoice';
export type ContractInformation = { contractValue: number; contractPriceDetails: string };

// Chargify subscription possible state
export type SubscriptionState = 'active' | 'past_due' | 'expired' | 'canceled';

export type SubscriptionIdentification = Pick<
  UserStatus,
  'forceAllow' | 'hasSubscription' | 'hasTestTime' | 'isUserFromAngularTs' | 'trialDaysLeft'
> & {
  state: SubscriptionState;
};

// Getting pricing info from chargify BE
export type SubscriptionProductPrice = {
  ending_quantity: number;
  starting_quantity: number;
  unit_price: number;
};

export interface ProductData {
  ABM: ProductDetail;
  Advanced: ProductDetail;
  Basic: ProductDetail;
  Business: ProductDetail;
  Professional: ProductDetail;
  Target: ProductDetail;
}

export interface ProductDetail {
  monthly: ProductDurationDetails;
  yearly: ProductDurationDetails;
}

export interface ProductDurationDetails {
  automatic: ProductDurationDetailLink;
  componentId: number;
  costPerUser: number;
  invoice: ProductDurationDetailLink;
  minBookableAmount: number;
  productId: number;
}

export interface ProductDurationDetailLink {
  id?: number;
  signupPage: string;
}

export interface CalcPriceResponse {
  basePrices: CalcBasePrices;
  firstSlotPriceAdvancedPerUser: number;
  firstSlotPriceBasicPerUser: number;
  firstSlotPriceTargetPerUser: number;
  formatted: CalcPriceFormattedProduct;
  priceAdvanced: number;
  priceAdvancedPerUser: number;
  priceBasic: number;
  priceBasicPerUser: number;
  priceTarget: number;
  priceTargetPerUser: number;
  secondSlotPriceAdvancedPerUser: number;
  secondSlotPriceBasicPerUser: number;
  secondSlotPriceTargetPerUser: number;
  thirdSlotPriceAdvancedPerUser: number;
  thirdSlotPriceBasicPerUser: number;
  thirdSlotPriceTargetPerUser: number;
}

export interface CalcPriceFormattedProduct {
  advanced: CalcPriceFormattedType;
  basic: CalcPriceFormattedType;
  target: CalcPriceFormattedType;
}

export interface CalcPriceFormattedType {
  afterComma: string;
  beforeComma: string;
}

export interface CalcBasePrices {
  Advanced: CalcPriceProductTypeDuration;
  Basic: CalcPriceProductTypeDuration;
  Business: CalcPriceProductTypeDuration;
  Professional: CalcPriceProductTypeDuration;
  Target: CalcPriceProductTypeDuration;
}

export interface CalcPriceProductTypeDuration {
  minBookableAmount?: number;
  monthly: CalcPriceProductTypeDurationDetails;
  yearly: CalcPriceProductTypeDurationDetails;
}

export interface CalcPriceProductTypeDurationDetails {
  firstSlot: { costPerUser: number };
  secondSlot: { costPerUser: number };
  thirdSlot: { costPerUser: number };
}

export interface PriceData {
  additionalUsers: number; // Extra users past planMinAmount
  advancedBig: string;
  advancedSmall: string;
  amountOfUsers: number;
  basePrices: CalcBasePrices;
  basicBig: string;
  basicSmall: string;
  minAmountWhichIsBookable: number; // Min amount of users the account must book
  planMinAmount: number; // Min amount of users the plan requires to be booked
  priceAdvancedPerUser: number;
  priceBasicPerUser: number;
  pricePerUser: number;
  priceTargetPerUser: number;
  targetBig: string;
  targetSmall: string;
  totalAmount: number;
  totalUsers: number; // Total booked users => planMinAmount + additionalUsers and must be >= minAmountWhichIsBookable
}

export interface BookingConfig {
  billingInterval: ProductDuration;
  defaultPlan: Product;
  maxUsers: number;
  minAmountForInvoice: number;
  paymentMethod: string;
}

export interface AddPaymentCredentials {
  billingInterval: ProductDuration;
  chargifyCustomerNumber: number;
  hasMultiSignature: boolean;
  product: Product;
}

export interface UserStatus {
  amountOfFreeMembers: number;
  amountOfMembers: number;
  amountOfUsers: number;
  billingInterval: ProductDuration;
  contractValue: number;
  contractPriceDetails: string;
  currentAmountOfLicenses: number;
  currentProduct: Product;
  customPrice: string;
  firstSlotUsers: number;
  forceAllow: boolean; // Is activated manually by us
  forwardMailsTo?: boolean;
  hasMultiSignature: boolean;
  hasSubscription: boolean; // Has a subscription
  hasTestTime: boolean; // Is still in the testing phase,
  interval: number;
  isCommunityEditionAccount: boolean;
  isLocked: boolean; // Trial is expired
  isUserFromAngularTs: boolean; // After signup 30.01.2023
  mailPolicy: string;
  monthlyPricePerUser: number;
  priceList: SubscriptionProductPrice[];
  secondSlotUsers: number;
  state: SubscriptionState;
  success: boolean;
  thirdSlotUsers: number;
  trialDaysLeft: number;
}

// To check subscription object type is CustomerHasFreeSubscription
export const isFreeSubscription = (subscription: unknown): subscription is CustomerHasFreeSubscription => {
  return (subscription as CustomerHasFreeSubscription).hasFreeSubscription === true;
};

// To check object type is authorization scope
export const isAuthorizationsScopeInstance = (obj: unknown): obj is IMAILTASTIC_AUTHORIZATION_SCOPE => {
  return !!(obj as IMAILTASTIC_AUTHORIZATION_SCOPE).adminId;
};

export interface CustomerHasFreeSubscription {
  hasFreeSubscription: boolean;
}

export interface CustomerSubscriptionData {
  accountId: string;
  component: {
    allocated_quantity: number;
    name: string;
  };
  credit_card: {
    card_type: string;
    expiration_month: number;
    expiration_year: number;
    masked_card_number: string;
  };
  customer: {
    address: string;
    city: string;
    country: string;
    email: string;
    first_name: string;
    last_name: string;
    organization: string;
    vat_number: string;
    zip: string;
  };
  customFields: [
    {
      name: string;
      value: number;
    }
  ];
  payment_collection_method: string;
  payment_type: string;
  pricePerUser: number;
  priceList: SubscriptionProductPrice[];
  product: {
    interval_unit: string;
    interval: number;
  };
  state: SubscriptionState;
}

export interface SubscriptionInvoices {
  invoice: {
    amount_due_in_cents: number;
    canceled_at?: Date;
    created_at: Date;
    id: string;
    number: string;
    paid_at: Date;
    state: string;
    total_amount_in_cents: number;
  };
}

export interface SubscriptionInvoicesAutomaticPayment {
  createdAt: Date;
  downloadurl: string;
  id: string;
}

export interface BookingContainer {
  general: BookingContainerCategoryInfo;
  openStep1: boolean;
  openStep2: boolean;
  openStep3: boolean;
  signatureManagement: BookingContainerCategoryInfo;
  signatureMarketing: BookingContainerCategoryInfo;
}

export interface BookingContainerCategoryInfo {
  advanced: BookingContainerPlanInfo;
  basic: BookingContainerPlanInfo;
  free: BookingContainerPlanInfo;
  items: string[];
  target: BookingContainerPlanInfo;
  title: string;
}

export interface BookingContainerPlanInfo {
  excludes: string[];
  includes: string[];
}
